import { useState } from "react";
import { useIonRouter } from "@ionic/react";
import { FieldValues, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { request } from "@/utils/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { useSetToken } from "@/hooks/authentication";
import { TextInput } from "@/components/inputs/text-input";
import { PasswordInput } from "@/components/inputs/password-input";
import { Button } from "@/components/button";

import signInBackgroundLeft from "@/assets/sign-in-background-left.jpg";
import signInBackgroundRight from "@/assets/sign-in-background-right.jpg";
import signInBackgroundMobile from "@/assets/sign-in-background-mobile.jpg";

const SignInPage = () => {
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    control,
  } = useForm<FieldValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const router = useIonRouter();
  const setToken = useSetToken();
  const [formError, setFormError] = useState<string>();

  const onSubmit = async (data: unknown) => {
    setFormError(undefined);

    const response = await request.post<{ token: string }>(
      "/users/sessions",
      data,
    );

    if (response.data) {
      await setToken(response.data.token);

      reset();

      router.push("/songs");
    } else {
      setFormError(response.message);
    }
  };

  return (
    <UnauthenticatedPageLayout className="flex flex-col gap-4">
      {/* <div className="bg-amber-200 rounded p-4 text-amber-800 flex flex-col gap-2">
            <h3 className="text-2xl font-semibold">
              You are outside the United States
            </h3>

            <p>
              <em>Sing and Speak 4 Kids</em> is not currently available outside
              the United States.
            </p>

            <p>
              You will not be able to register or login unless you are inside
              the United States.
            </p>

            <p>
              If you have any questions, please send us an email at{" "}
              <a href="mailto:SS4KidsSupport@iqsonics.com?subject=SS4Kids%20Outside%20USA%3A%20">
                SS4KidsSupport@iqsonics.com
              </a>
            </p>
          </div> */}

      <div className="grid grid-cols-3">
        <div className="m-6 mr-0 border-l border-y rounded-l hidden sm:block">
          <img src={signInBackgroundLeft} className="rounded-l" />
        </div>

        <div className="rounded p-6 bg-neutral-100 border flex flex-col  gap-4 sm:col-span-1 col-span-3">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 justify-between h-full"
          >
            <div className="flex flex-col gap-4">
              <TextInput
                name="email"
                control={control}
                placeholder="EMAIL"
                autoCapitalize="off"
                endAdornment={
                  <div className="flex items-center justify-center bg-gray-200 rounded-r border border-gray-500">
                    <FontAwesomeIcon
                      icon="envelope"
                      fixedWidth
                      className="px-3"
                    />
                  </div>
                }
              />

              <PasswordInput
                name="password"
                control={control}
                placeholder="PASSWORD"
                autoCapitalize="off"
              />
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                className="block bg-blue-500 text-center text-white rounded-xl px-4 py-3 w-[160px] font-medium text-xl"
                loading={isSubmitting}
              >
                SIGN IN
              </Button>
            </div>

            {formError && (
              <p className="text-red-500 text-center">{formError}</p>
            )}

            <div className="text-center">
              <Link to="/forgot-password" className="link">
                Forgot your password?
              </Link>
            </div>
          </form>
        </div>

        <div className="m-6 ml-0 border-r border-y rounded-r hidden sm:block">
          <img src={signInBackgroundRight} className="rounded-r" />
        </div>
      </div>

      <hr className="w-[200px] mx-auto" />

      <div className="flex gap-2 justify-center items-center">
        <span>New member?</span>

        <Link to="/sign-up" className="btn-secondary rounded-lg py-2 px-6">
          REGISTER
        </Link>
      </div>

      <img src={signInBackgroundMobile} className="sm:hidden" />
    </UnauthenticatedPageLayout>
  );
};

export default SignInPage;
